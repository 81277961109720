.homeContact {
    background: url(../../Img/backgroundInfo.png);
    padding: 1rem;
    padding-top: 5rem;
}

.textContact {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.045); /*#625555 #ab530196 */
    border-radius: 5px;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 2px 2px 10px #625555;
    margin: 0 1rem 0 1rem;
}

.textContact h2 {
    font-size: 45px;
}

.textContact p {
    font-size: 22px;
}


/* ----------- */

.formContact {
    background: rgba(255, 255, 255, 0.045); /*#625555 #ab530196 */
    border-radius: 5px;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 2px 2px 10px #625555;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60vh;
    gap: 5rem;
}

.allenar img {
    height: 280px;
    border: 2px solid #0436ea7e;  /*  #ab530180 */
    padding: .3rem;
    border-radius: 13px;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 14rem;
}

.inputForm {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #0436ea7e;  /*  #ab530180 */
    height: 2rem;
    font-size: 20px;
}

.inputFormArea {
    background: transparent;
    border: none;
    border-bottom: 2px solid #0436ea7e;  /*  #ab530180 */
    font-size: 20px;
    resize: none;
    height: 5rem;
    width: 20rem;
}

.buttonForm {
    width: 10rem;
    height: 2rem;
    font-size: 20px;
    background-color: #0436ea7e;  /*  #ab530180 */
    color: #ffffff;
    border: 1px solid #0436ea7e;  /*  #ab530180 */
    border-radius: 3px;
    box-shadow: 2px 2px 10px #625555;
    text-shadow: 1px 1px 5px #625555;
}

.buttonForm:hover {
    background-color: white;
    color: #0436ea7e;  /*  #ab530180 */
    font-weight: bold;
    transition: all ease-in-out .2s;
    cursor: pointer;
    border: none;
}

/* ------------------------- */

@media (max-width: 1440px) {
        .textContact {
        padding: 1rem;
    }

    .textContact h2 {
        font-size: 55px;
        text-shadow: 1px 1px 5px #625555;
    }

    .textContact p {
        font-size: 30px;
        text-shadow: 1px 1px 5px #625555;
        line-height: 60px;
    }

    .input {
        width: 25rem;
    }

    .inputFormArea {
        width: 100%;
    }
}

@media (max-width: 1024px) {

    .textContact {
        padding: 1rem;
    }

    .textContact h2 {
        font-size: 55px;
        text-shadow: 1px 1px 5px #625555;
    }

    .textContact p {
        font-size: 30px;
        text-shadow: 1px 1px 5px #625555;
        line-height: 60px;
    }

    .input {
        width: 15rem;
    }

    .inputFormArea {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .homeContact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .textContact {
        height: 60vh;
        width: 90%;
        padding: .5rem;
    }

    .textContact h2 {
        font-size: 60px;
        text-shadow: 1px 1px 5px #625555;
        text-align: center;
    }

    .textContact p {
        font-size: 34px;
        line-height: 70px;
        text-shadow: 1px 1px 5px #625555;
        text-align: center;
    }

    .formContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        width: 90%;
        gap: 2rem;
    }

    .input {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 28rem;
    }

    .inputForm {
        width: 80%;
    }

    .inputFormArea {
        width: 80%;
    }

    .buttonForm {
        width: 60%;
        height: 3rem;
        font-size: 30px;
    }

    .buttonForm:hover {
        border: 1px solid white;
        background-color: white;
        color: #ab5301da;
        transition: all ease .3s;
        box-shadow: 1px 1px 10px #625555;
        font-weight: bolder;
        cursor: pointer;
    }
}

@media (max-width: 600px) {

    .homeContact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 5rem;
    }

    .textContact {
        height: 70vh;
        width: 90%;
        padding: .5rem;
    }

    .textContact h2 {
        font-size: 45px;
        text-shadow: 1px 1px 5px #625555;
    }

    .textContact p {
        font-size: 28px;
        line-height: 50px;
        text-shadow: 1px 1px 5px #625555;
    }

    .formContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 95vh;
        width: 90%;
        gap: 2rem;
    }

    .input {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .inputForm {
        width: 60%;
    }

    .inputFormArea {
        width: 60%;
    }

    .buttonForm {
        width: 60%;
        height: 3rem;
        font-size: 30px;
    }

    .buttonForm:hover {
        border: 1px solid white;
        background-color: white;
        color: #ab5301da;
        transition: all ease .3s;
        box-shadow: 1px 1px 10px #625555;
        font-weight: bolder;
        cursor: pointer;
    }

}