.homeServices {
    background: url(../../Img/backgroundInfo.png);
    background-position: contain;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-top: 1rem;
}

.bannerServices {
    height: 25vh;
    background: url(../../Img/background-home.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; 
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerServices h2 {
    font-size: 45px;
}

.titleServices {
    text-align: center;
    font-size: 25px;
    margin-bottom: 1rem;
}

.descServices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;
}

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    border-left: 2px solid #0436ea7e;  /*  #ab530180 */
    border-right: 2px solid #0436ea7e;  /*  #ab530180 */
    border-radius: 3px;
    box-shadow: 2px 2px 10px #625555;
    padding: .5rem;
}

.services:hover {
    transform: scale(1.05);
    transition: all ease .3s;
    background-color: #0436ea7e;  /*  #ab530180 */
    border: none;
    border-radius: 3px;
}

.services img {
    height: 30px;
}

.services h4 {
    font-size: 25px;
}

.services p{
    font-size: 20px;
    text-shadow: 1px 1px 5px #625555;
}

.serviceTitles {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

@media (min-width: 377px) and (max-width: 426px) {
    .descServices {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services {
        width: 90vw;
        margin: .8rem;
    }

    .services p{
        display: none;
    }
}

@media (min-width: 321px) and (max-width: 376px) {
    .descServices {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services {
        width: 90vw;
        margin: .8rem;
    }

    .services p{
        display: none;
    }
}

@media (max-width: 320px) {
    .descServices {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services {
        width: 90vw;
        margin: .8rem;
    }

    .services p{
        display: none;
    }
}
