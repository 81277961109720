.privacy-page {
    display: flex;
    flex-direction: column;
    padding: 6rem;
    background: url(../../Img/backgroundInfo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

h2 {
    font-size: 40px;
    font-weight: 500;
}

h3 {
    font-size: 30px;
    font-weight: 500;
    margin: 12px 0px 12px 0px;
}

p {
    font-size: 20px;
    font-weight: 400;
}

.privacy-page>p {
    font-size: 18px;
    margin-top: 1rem;
}