
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap');

/* RESET CSS*/

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'EB Garamond', sans-serif;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: .6rem;
}

::-webkit-scrollbar-track {
  background: #555;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0436ead2;
}



