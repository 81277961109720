.homePrices {
    min-height: 100vh;
    background: url(../../Img/backgroundInfo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    padding-top: 5rem;
}

.textPrices {
    background: rgba(255, 255, 255, 0.045); /*#625555 #ab530196 */
    border-radius: 5px;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 2px 2px 10px #625555;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 88vw;
    margin-top: 1rem;
    padding: 1rem;
}

.textPrices h2 {
    font-size: 50px;
    text-shadow: 1px 1px 5px #625555;
    color: rgb(59, 50, 40);
}

.textPrices p {
    font-size: 30px;
    text-shadow: 1px 1px 5px #625555;
    color: rgb(59, 50, 40);
    padding: .5rem;
    font-weight: 500;
}

.textPrices span {
    font-size: 23px;
    text-shadow: 1px 1px 5px #625555;
}

.aboutRight img {
    border: 2px solid #0436ea7e;  /*  #ab530180 */
    border-radius: 13px;
    padding: .3rem;
}

.homePrices .separator {
    border-top: 5px solid #0436ea7e;  /*  #ab530180 */
}

/* ---------------------- */

@media (max-width: 1440px) {
    .textPrices {
        margin: 1rem;
    }

    .textPrices h2 {
        font-size: 55px;
    }

    .textPrices p {
        font-size: 35px;
    }

    .textPrices span {
        font-size: 26px;
        line-height: 40px;
    }

    .aboutContact {
        width: 90%;
    }
}

@media (max-width: 1024px) {
    .textPrices {
        margin: 1rem;
    }

    .textPrices h2 {
        font-size: 55px;
    }

    .textPrices p {
        font-size: 35px;
    }

    .textPrices span {
        font-size: 26px;
        line-height: 40px;
    }

    .aboutContact {
        width: 90%;
    }

    .about {
        flex-wrap: nowrap;
    }
}

@media (max-width: 800px) {
    .textPrices {
        margin: 1rem;
    }

    .textPrices h2 {
        font-size: 55px;
    }

    .textPrices p {
        font-size: 35px;
    }

    .textPrices span {
        font-size: 26px;
        line-height: 40px;
    }

    .aboutContact {
        width: 90%;
    }
}

@media (max-width: 600px) {

    .textPrices {
        height: 100%;
        margin: 1rem;
    }

    .textPrices h2 {
        font-size: 55px;
        text-align: center;
    }

    .textPrices p {
        font-size: 35px;
        text-align: center;
    }

    .textPrices span {
        font-size: 26px;
        line-height: 40px;
    }

    .aboutContact {
        width: 90%;
    }
}