#main {
    background: url(../../Img/backgroundInfo.png);
    background-position: contain;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5% 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    border-top: 5px solid #0436ea7e; /* #ab530180 */
}

.mainInfo {
    background: rgba(255, 255, 255, 0.045); /*#625555 #ab530196 */
    height: 100%;
    width: 100%;
    border-radius: 5px;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 2px 2px 10px #625555;
}

.infoText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.infoText h3{
    font-size: 30px;
    line-height: 50px;
    width: 50%;
    text-align: center;
    text-shadow: 1px 1px 5px #625555;
}

.infoText p {
    font-size: 18px;
    line-height: 25px;
    width: 85%;
    text-shadow: 1px 1px 5px #625555;
}

.infoDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    text-shadow: 1px 1px 5px #625555;
}

.descInfo-1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
}

.descInfo-2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
}

.desc {
    text-align: center;
    width: 50%;
    height: 100%;
    border-left: 2px solid #0436ea7e;  /*  #ab530180 */
    border-right: 2px solid #0436ea7e;  /*  #ab530180 */
    border-radius: 3px;
    padding: 1rem;
    box-shadow: 2px 2px 10px #625555;
}

.desc:hover {
    transform: scale(1.03);
    transition: all ease .3s;
    background-color: #0436ea7e;  /*  #ab530180 */
    border: none;
    border-radius: 3px;
}

.title {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.title h4 {
    text-shadow: 1px 1px 5px #625555;
}

@media (max-width: 2560px) {
    #main {
        padding: 1rem;
    }

    .desc {
        height: 14rem;
    }

    .infoText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .infoText h3 {
        font-size: 35px;
        width: 65%;
        padding: 5px;
        text-align: center;
    }

    .infoText p {
        font-size: 26px;
        text-align: center
    }

    .desc p {
        font-size: 26px;
    }

    .title h4 {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    #main {
        padding: 1rem;
    }

    .desc {
        height: 14rem;
    }

    .infoText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .infoText h3 {
        font-size: 35px;
        width: 65%;
        padding: 5px;
        text-align: center;
    }

    .infoText p {
        font-size: 26px;
        text-align: center
    }

    .desc p {
        font-size: 26px;
    }

    .title h4 {
        font-size: 30px;
    }
}

@media (max-width: 1024px) {
    #main {
        padding: 1rem;
    }

    .desc {
        height: 14rem;
    }

    .infoText h3 {
        font-size: 30px;
        width: 65%;
        padding: 5px;
        text-align: center;
    }

    .infoText p {
        font-size: 26px;
        text-align: center
    }

    .desc p {
        font-size: 24px;
    }

    .title h4 {
        font-size: 25px;
    }
}

@media (max-width: 800px) {
    #main {
        padding: 1rem;
    }

    .desc {
        height: 13rem;
    }

    .infoText h3 {
        font-size: 28px;
        width: 65%;
        padding: 5px;
        text-align: center;
    }

    .infoText p {
        font-size: 25px;
        text-align: center
    }

    .infoDesc {
        padding: 1rem;
    }

    .desc p {
        font-size: 21.4px;
    }

    .title h4 {
        font-size: 21px;
    }
}

@media (max-width: 600px) {
    .mainInfo {
        height: 100vh;
    }

    .desc {
        height: 100%;
    }

    .infoText p{
        display: none;
    }
    
    .infoText h3 {
        font-size: 22px;
        width: 100%;
        padding: 5px;
        margin-bottom: 1.5rem;
        line-height: 35px;
    }

    .desc p {
        display: none;
    }

    .infoDesc {
        height: 70vh;
        padding: 1px;
        margin-top: 30px;
    }

    .descInfo-1 {
        flex-direction: column;
        width: 100vw;
    }

    .descInfo-2 {
        flex-direction: column;
        width: 100vw;
    }

    .title {
        flex-direction: column;
    }

    .title h4 {
        font-size: 20px;
        text-align: center;
    }

    .title img {
        height: 55px;
    }
}