#mainTeam {
    height: 100vh;
    background: url(../../Img/backgroundInfo.png);
    background-position: contain;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5% 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    border-top: 5px solid #0436ea7e;  /*  #ab530180 */ 
}

.teamMain {
    background: rgba(255, 255, 255, 0.045); /*#625555 #ab530196 */
    height: 30rem;
    width: 100%;
    border-radius: 5px;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 2px 2px 10px #625555;
}

.teamText h3 {
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    text-shadow: 1px 1px 5px #625555;
}

.teamAdmin {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}

.admin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    text-shadow: 1px 1px 5px #625555;
    width: 100%;
    border-left: 2px solid #0436ea7e;  /*  #ab530180 */
    border-right: 2px solid #0436ea7e;  /*  #ab530180 */
    border-radius: 3px;
    box-shadow: 2px 2px 10px #625555;
    margin: 1rem;
    padding: .5rem;
}

.admin:hover {
    transform: scale(1.03);
    transition: all ease .3s;
    background-color: #0436ea7e;  /*  #ab530180 */
    color: white;
    border: none;
    border-radius: 3px;
    text-shadow: 1px 1px 5px #625555;
}

.admin img {
    height: 100px;
    width: 100px;
    color: white;
    object-fit: cover;
}

.teamDesc p {
    margin-top: 1rem;
    font-size: 20px;
    text-shadow: 1px 1px 5px #625555;
    padding: 1rem;
    text-align: center;
}

@media (max-width: 2560px) {
    #mainTeam {
        padding: 1rem;
        border-top: none;
    }

    .teamMain {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .teamText h3 {
        font-size: 50px;
    }

    .teamAdmin {
        gap: 0;
        margin-top: 0;
    }

    .teamDesc p{
        font-size: 26px;
    }

    .admin {
        height: 24rem;
    }

    .admin img {
        height: 80px;
        width: 80px;
        color: white;
        object-fit: cover;
    }

    .admin h4 {
        font-size: 27px;
    }

    .admin p {
        font-size: 24px;
    }
}

@media (max-width: 1440px) {
    #mainTeam {
        padding: 1rem;
        border-top: none;
    }

    .teamMain {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .teamText h3 {
        font-size: 50px;
    }

    .teamAdmin {
        gap: 0;
        margin-top: 0;
    }

    .teamDesc p{
        font-size: 26px;
    }

    .admin {
        height: 24rem;
    }

    .admin img {
        height: 80px;
        width: 80px;
        color: white;
        object-fit: cover;
    }

    .admin h4 {
        font-size: 27px;
    }

    .admin p {
        font-size: 24px;
    }
}

@media (max-width: 1024px) {
    #mainTeam {
        padding: 1rem;
        border-top: none;
    }

    .teamMain {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .teamText h3 {
        font-size: 45px;
    }

    .teamAdmin {
        gap: 0;
        margin-top: 0;
    }

    .teamDesc p{
        font-size: 26px;
    }

    .admin {
        height: 24rem;
    }

    .admin img {
        height: 80px;
        width: 80px;
        color: white;
        object-fit: cover;
    }

    .admin h4 {
        font-size: 27px;
    }

    .admin p {
        font-size: 24px;
    }
}

@media (max-width: 800px) {
    #mainTeam {
        padding: 1rem;
        border-top: none;
    }

    .teamMain {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .teamAdmin {
        gap: 0;
        margin-top: 0;
    }

    .teamDesc p{
        font-size: 25px;
    }

    .admin {
        height: 23rem;
    }

    .admin img {
        height: 60px;
        width: 60px;
        color: white;
        object-fit: cover;
    }

    .admin h4 {
        font-size: 25px;
    }

    .admin p {
        font-size: 22px;
    }
}

@media (max-width: 600px) {

    #mainTeam {
        border-top: none;
    }

    .teamMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    .teamAdmin {
        width: 87%;
        flex-direction: column;
        gap: 1.5rem;
    }

    .teamText h3 {
        width: 80vw;
        margin-left: 0px;
    }

    .teamText {
        text-align: center;
    }

    .admin p {
        display: none;
    }

    .teamDesc p {
        display: none;
    }

    .admin {
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
        height: 10rem;
    }

    .admin img {
        height: 100px;
        width: 100px;
    }

    .admin h4 {
        font-size: 25px;
        font-weight: 400;
        text-shadow: 0 0 0;
    }
}