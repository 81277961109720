nav {
    width: 100%;
    height: 10vh;
    padding: 1rem;
    position: fixed;
    z-index: 3;
    opacity: 23rem;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.29);
    box-shadow: 6px 7px 5px rgba(0, 0, 0, 0.377);
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.navbar .scrolled {
    background-color: white !important;
}
.navbar .scrolled a{
    color: black;
    transition: 0.6s ease all;
}

.title-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0436ead2; /* #ab5301da => orange */
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    margin: .5rem;
}

.title-nav p {
    text-shadow: 3px 3px 8px black;
}

.title-nav:hover {
    background-color: #0436ead2;/* #ab5301da => orange */
    color: white;
    transition: all ease .3s;
    border: 1px solid transparent;
    border-radius: 2px;
}

.nav_items a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px;
    color: #fff;
    position: relative;
    text-decoration: none;
    display: none;
    text-align: center;
    padding: 10px 10rem;
    height: 2.5rem;
}

.nav_items a:hover {
    background-color: #0436ea5a;
    color: white;
    position: relative;
    text-decoration: none;
    text-align: center;
    padding: 10px 10rem;
    font-weight: bold;
    border-radius: 5px;
    transition: all ease .3s;
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav_items {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.porfile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.porfile-text {
    text-align: center;
    color: #fff;
    text-shadow: 3px 3px 8px black;
}

.porfile-text p {
    font-weight: 600;
    font-size: 20px;
    margin-top: 15px;
}

.nav_items {
    z-index: 800;
    position: fixed;
    top: 0px;
    left: 0;
    background: rgba(128, 128, 128, 0.833);  /* #864101e8 => orange */
    display: flex;
    flex-direction: column;
    transform: translateY(-100%);
    transition: all ease 1s;
    height: 100vh;
    border-radius: 7px;
}

.nav_items.open {
    transform: translateX(0) !important
}

.nav_items a {
    display: flex;
}

.nav_toggle {
    display: flex !important;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
}

.nav_toggle span {
    width: 30px;
    height: 4px;
    background: #0436ead2; /* #ab5301af => orange */ 
    margin-bottom: 5px;
    border-radius: 2px;
    transform-origin: 5px 0px;
    transition: all ease 1s;
}

.nav_toggle {
    z-index: 10000;
}

.nav_toggle.open>span {
    transform: rotate(45deg) translate(-1px, -20px);
    margin-top: -8px;
    margin-left: 370px;
    color: #0436ead2;
    z-index: 10000;
    position: fixed;
}

.nav_toggle.open>span:nth-child(2) {
    display: none;
}

.nav_toggle.open>span:nth-child(3) {
    transform: rotate(-45deg) translate(15px, 1px);
    margin-top: 1px;
    color: #0436ead2;
}

.nav_items {
    display: flex;
    padding-top: 30px;
    gap: 1rem;
}

/* @media (max-width: 2560px) {
    nav {
        width: 100%;
        height: 10vh;
        padding: .5rem;
    }

    .porfile-text {
        margin-bottom: 4rem;
    }

    .porfile-text p {
        font-size: 32px;
        margin-top: 1rem;
    }

    .nav_items a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 5vh;
        text-shadow: 2px 2px 10px #000000;
    }

    .nav_items a:hover {
        background-color: #ffffff50;
        color: white;
        position: relative;
        text-decoration: none;
        text-align: center;
        padding: 10px 10rem;
        font-weight: bold;
        border-radius: 5px;
        transition: all ease .3s;
    }
} */

@media (max-width: 1440px) {
    nav {
        width: 100%;
        height: 10vh;
        padding: .5rem;
    }

    .porfile-text {
        margin-bottom: 4rem;
    }

    .porfile-text p {
        font-size: 32px;
        margin-top: 1rem;
    }

    .nav_items a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 5vh;
        text-shadow: 2px 2px 10px #000000;
    }
}

@media (max-width: 1024px) {
    nav {
        width: 100%;
        height: 10vh;
        padding: .5rem;
    }

    .porfile-text {
        margin-bottom: 4rem;
    }

    .porfile-text p {
        font-size: 32px;
        margin-top: 1rem;
    }

    .nav_items a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 5vh;
        text-shadow: 2px 2px 10px #000000;
    }
}

@media (max-width: 800px) {
    nav {
        width: 100%;
        height: 10vh;
        padding: .5rem;
    }

    .nav_items {
        height: 100vh;
        justify-content: center;
        align-items: center;
        width: 75vw;
    }

    .porfile-text {
        margin-bottom: 4rem;
    }

    .porfile-text p {
        font-size: 32px;
        margin-top: 1rem;
    }

     .nav_items a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 3rem;
        text-shadow: 2px 2px 10px #000000;
    }
    
    .nav_toggle.open>span {
        transform: rotate(45deg) translate(0px, -3px);
        margin-top: -10px;
        margin-left: 60%;
        background: #0436ead2;
    }

    .nav_toggle.open>span:nth-child(3) {
        transform: rotate(-45deg) translate(0px, 0px);
        margin-left: 60%;
        background: #0436ead2;
    }
}

@media (max-width: 600px) {
    nav {
        width: 100%;
        height: 10vh;
        padding: .5rem;
    }

    .nav_items {
        height: 100vh;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }

    .porfile-text {
        margin-bottom: 4rem;
    }

    .nav_items a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 3rem;
        text-shadow: 2px 2px 10px #000000;
    }

    .nav_items a:hover {
        background-color: #ffffff50;
        color: white;
        position: relative;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        border-radius: 5px;
        transition: all ease .3s;
        height: 3rem;
    }
    
    .nav_toggle.open>span {
        transform: rotate(45deg) translate(0px, -4px);
        margin-top: -10px;
        margin-left: 70%;
        background: #0436ead2;
    }

    .nav_toggle.open>span:nth-child(3) {
        transform: rotate(-45deg) translate(0px, 0px);
        margin-left: 70%;
        background: #0436ead2;
    }

    .porfile-text p {
        font-size: 32px;
        margin-top: 1rem;
    }
}